import React from "react";
import "../styles/Home.css";
import Navbar from "../components/Navbar.js";

function Home() {
  return (
    <div className="home">
      <Navbar></Navbar>
      <div className="about">
        <h2> Hi, I'm Adviti Mishra </h2>
        <div className="prompt">
          <p>
            I'm a senior majoring in Computer Science with a minor in Business
            at The University of Michigan Ann Arbor.
          </p>
          <p>
            I'm passionate about software engineering, product, venture capital, and entrepreneurship. Within these, software engineering is my primary interest.
            In the tech world, I enjoy writing library code, teaching, and participating and organizing hackathons.
          </p>
          <p>
            Outside of tech, I love distance running, playing my guitar,
            writing, and curating fun facts.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
