import MediHealthImg from "../assets/MediHealth.png";
import AccessAssistImg from "../assets/AccessAssist.png";
import FunFactsFlashImg from "../assets/FunFactFlash.png";
import SwiftStatsImg from "../assets/SwiftStats.png";
import LexofinImg from "../assets/LexoFinFinal.png";
import PortfolioImg from "../assets/PersonalPortfolio.png";
import MediSumImg from "../assets/MediSum.png";
import DoubletImg from "../assets/Doublet.png";

export const mainProjectList = [
  {
    name: "Doublet",
    image: DoubletImg,
    description:
      "An online version of the word game - Doublet - where users have to get from a start word to an end word by changing one letter at a time where each intermediate word needs to be a valid word ",
    skills: "React, TypeScript, Next.js, MongoDB, Netlify, Git",
    gitHubLink: "https://github.com/adviti-mishra/doublet",
  },
  {
    name: "MediSum (HackMIT)",
    image: MediSumImg,
    description:
      "A web application showing a map of how welcoming care centers are by conducting sentiment analysis using IBM Watson and summarization using Facebook’s BART on online reviews in 24 hours ",
    skills: "React, JavaScript, kepler.gl, IBM Watson, BART, Python, Git",
    gitHubLink: "https://github.com/adviti-mishra/HackMITWeCare",
    featured:
      "https://www.linkedin.com/posts/markusvankempen_wastonx-ibm-hackmit-activity-7109881471204540416-R-ha?utm_source=share&utm_medium=member_desktop",
  },
  {
    name: "Fun Facts Flash",
    image: FunFactsFlashImg,
    description:
      "A web application where users can add and read fun facts filtered by themes",
    skills: "React, Javascript, HTML, CSS, Supabase, Netlify, Git",
    gitHubLink: "https://github.com/adviti-mishra/Fun-Facts-Flash",
  },
  {
    name: "Access Assist (BU's Tech for Change Hackathon)",
    image: AccessAssistImg,
    description:
      "A web application that shows users in Boston neaby benefit programs based on their location and  what national benefit programs they are eligible for based on their demographic data.",
    skills:
      "React, Javascript, HTML, CSS, Flask, Pandas, Python, Netlify, Figma, Git",
    gitHubLink: "https://github.com/XinyuP/Access_Assist",
  },
  {
    name: "Swift Stats",
    image: SwiftStatsImg,
    description:
      "A web application that shows rankings of songs in Taylor Swift's albums by Spotify's internal populrity index. Also provides side-by-side comparisons of Taylor Swift's original version and re-recorded version",
    skills: "React, Javascript, HTML, CSS, Spotify API, Git",
    gitHubLink: "https://github.com/mariafwf/swiftstats",
  },
  {
    name: "LexoFin (Build Blue Hackathon)",
    image: LexofinImg,
    description:
      "A CLI MVP of a chrome extension that provides simplified explanations of finance articles using dynamic prompts based on user's culture and identity. Built in a team of 4. Won honorable mention",
    skills: "OpenAI API, JavaScript, Chrome Extensions",
    gitHubLink: "",
  },
  {
    name: "Portfolio Website",
    image: PortfolioImg,
    description: "This website itself haha : )",
    skills: "React, CSS, Hostinger",
    gitHubLink: "https://github.com/adviti-mishra/personal-portfolio",
  },
];
